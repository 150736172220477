// https://github.com/grommet/grommet/wiki/Grommet-v2-theming-documentation
// https://github.com/grommet/grommet/tree/NEXT/src/js/themes
export default {
  global: {
    font: {
      family: "Fira Sans"
    },
    colors: {
      brand: "#222426"
    },
    breakpoints: {
      xsmall: {
        value: 300
      },
      small: {
        value: 600
      },
      medium: {
        value: 900
      },
      middle: {
        value: 1200
      }
    }
  },
  heading: {
    font: {
      family: "Fira Sans"
    }
  }
};